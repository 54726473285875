import React from "react";
import styled from "styled-components";

import {
  IoLogoFacebook,
  IoLogoLinkedin,
} from "react-icons/io";

import { Breadcrumb } from "../../components/site";
import { Container, Wrapper, Row, Box } from "../../components/util";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import SwitchPayrollHeader from "../../images/guide/SwitchingPayrollGuideNew.png";
import { navigate } from "gatsby";

const SocialShare = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 25px;
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  > * + * {
    margin-left: 15px;
  }
`;

const GetGuideButton = styled.button`
  background-color: #004763;
  border-radius: 10px;
  width: 15%;
  margin-top: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100% !important;
  }
`;

const ImageBox = styled(Box)`
  margin-bottom: 150px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 50px 0;
  }
`;

const PayrollGuide = (props) => {

  const handleShare = function (e) {
    if (typeof window !== "undefined") {
      if (e.target.name === "facebook") {
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" + props.location.href,
          "_blank",
          "width=555,height=760"
        );
      }

      if (e.target.name === "twitter") {
        window.open(
          "https://twitter.com/intent/tweet?text=" +
          "Switching Payroll Systems" +
          "&url=" +
          props.location.href,
          "_blank",
          "width=732,height=800"
        );
      }

      if (e.target.name === "linkedin") {
        window.open(
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          props.location.href +
          "&title=" +
          "Switching Payroll Systems",
          "_blank",
          "width=555,height=555"
        );
      }
    }
  };

  return (
    <Layout>
      <Seo
        title="Guide to Switching Payroll Systems | PayHero"
        description="Are you looking to change your payroll provider, but have no idea where to begin? Our step-by-step guide to switching payroll software ensures everything goes as smoothly as possible."
        image={
          process.env.GATSBY_SITE_URL +
          `/metaImages/GuideToSwitching_SiteMetaImage.jpg`
        }
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Resources"
        parentPageLink="/blog"
        currentPage="The Step-by-Step Guide to Switching Your Payroll System"
      />
      <Container className="primary">
        <Wrapper stackGap={80}>
          <Box className="-textCenter" stackGap={30}>
            <Box stackGap={15}>
              <div className="badge -md -dark">PAYROLL & FINANCE</div>
              <h1>
                The Step-by-Step Guide to Switching{" "}
                <br className="hideOnMobile" />
                Your Payroll System
              </h1>
              <GetGuideButton onClick={() => navigate("/media/guides/SwitchingPayrollGuide_PayHero.pdf")}>
                Get Guide
              </GetGuideButton>
            </Box>
            <SocialShare>
              <a
                href="#!"
                name="facebook"
                data-tooltip="Share on Facebook"
                onClick={handleShare}
              >
                <IoLogoFacebook css={{ pointerEvents: "none" }} />
              </a>
              <a
                href="#!"
                name="linkedin"
                data-tooltip="Share on LinkedIn"
                onClick={handleShare}
              >
                <IoLogoLinkedin css={{ pointerEvents: "none" }} />
              </a>
            </SocialShare>
          </Box>
        </Wrapper>
      </Container>
      <Container id="section2">
        <Wrapper stackGap={150}>
          <Row alignCenter>
            <Box size={65} stackGap={60}>
              <Box stackGap={60} className="font-bump">
                <div>
                  <h2>Is it time to switch your payroll software?</h2>
                  <p>
                    Our step-by-step guide to switching payroll software gives a comprehensive overview of what information you need and the steps to follow ahead of time. We also bust some common myths about changing payroll software to help put you at ease.
                  </p>
                  <p>
                    It’s okay to feel a little nervous about making changes to such a crucial piece of business software. But rather than stick with an old system that isn’t up to scratch, the key is to get prepared to minimise any disruption for you and your employees.
                  </p>
                  <p>
                    Download our guide and get your new payroll system up and running as quickly and smoothly as possible. You'll reap the rewards of improved business efficiency and save you and your team many hours in the future.
                  </p>
                </div>
                <h4 className="h3" css={{ marginBottom: "30px !important" }}>
                  What steps does the switching payroll guide cover?
                </h4>
                <ol>
                  <li>
                    How to choose a new provider
                  </li>
                  <li>
                    Gathering the right data from your old system
                  </li>
                  <li>
                    How to transfer the data to your new system
                  </li>
                  <li>
                    How to run a parallel pay to ensure everything is correct
                  </li>
                  <li>
                    Getting set up with integrations
                  </li>
                </ol>
                <p css={{
                  marginTop: "30px",
                }}>We also cover common FAQs and myths about switching payroll providers.</p>
                <GetGuideButton css={{ width: "25%", marginTop: "20px" }} onClick={() => navigate("/media/guides/SwitchingPayrollGuide_PayHero.pdf")}>
                  Get Guide
                </GetGuideButton>
              </Box>
            </Box>
            <ImageBox size={42}>
              <img
                src={SwitchPayrollHeader}
                alt="Guide to Switching Payroll Systems"
                rounded
                addShadow
              />
            </ImageBox>
          </Row>
          <SocialShare css={{ marginTop: "10px" }} >
            <a
              href="#!"
              name="facebook"
              data-tooltip="Share on Facebook"
              onClick={handleShare}
            >
              <IoLogoFacebook css={{ pointerEvents: "none" }} />
            </a>
            <a
              href="#!"
              name="linkedin"
              data-tooltip="Share on LinkedIn"
              onClick={handleShare}
            >
              <IoLogoLinkedin css={{ pointerEvents: "none" }} />
            </a>
          </SocialShare>
        </Wrapper>
      </Container>
    </Layout >
  );
};

export default PayrollGuide;
